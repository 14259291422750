import React from "react";
import logo_1 from "./../../assets/logo/Logo_Novagen_Pantone_trasparente.png";
import "./Footer.css";
import { t } from "i18next";

export default function Footer() {
  return (
    <footer id="Footer" className="text-white mt-5">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <div className="footer-logo">
              <img
                className="img-fluid"
                src={logo_1}
                alt="Logo de la empresa"
              />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <div className="footer-links">
              <h5>{t("quick_links")}</h5>
              <ul>
                <li className="p-2">
                  <a href="/" className="translated-text">{t("home")}</a>
                </li>
                <li className="p-2">
                  <a href="/productos" className="translated-text">{t("products")}</a>
                </li>
                <li className="p-2" >
                  <a href="/nosotros" className="translated-text">{t("about_us")} </a>
                </li>
                <li className="p-2">
                  <a href="/contacto" className="translated-text">{t("contact")} </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4  d-flex justify-content-center align-items-center">
            <div className="footer-contact">
              <h5 className="translated-text">{t("contact")}</h5>
              <p className="translated-text">
                {t("address")}: 4a Avenida 5-30 colonia Los Alamos Zona 6 San Miguel
                Petapa, Guatemala Guatemala, Centroamérica. Código postal: 01066
              </p>
              <p className="translated-text">{t("phone")}: PBX 2319- 9900 / 3104-2573</p>
              <p className="translated-text">{t("email")}: info@novagen.com.gt</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center">
        <p>
          &copy;{new Date().getFullYear()} {t("all_rights_reserved")}
        </p>
      </div>
    </footer>
  );
}
