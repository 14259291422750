import React from "react";
import "./Productos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import nitrato_de_amonio from "./../../assets/img/Productos/1_Nitrato_De_Amonio.jpg";
import nittrato_de_potasio from "./../../assets/img/Productos/2_Nitrato_De_Potasio.jpg";
import map_12_61_0 from "./../../assets/img/Productos/3_map_12_61_0.jpg";
import sulgato_de_potasio from "./../../assets/img/Productos/4_Sulfato_De_Potasio.jpg";
import acido_fosforico from "./../../assets/img/Productos/5_Ácido_Fosfórico.jpg";
import mkp_0_52_34 from "./../../assets/img/Productos/6_MKP_0_52_34.jpg";
import nitrato_de_calcio from "./../../assets/img/Productos/7_Nitrato_De_Calcio.jpg";
import muriato_de_potasio from "./../../assets/img/Productos/8_Muriato_De_Potasio.jpg";
import ureal from "./../../assets/img/Productos/9_Urea.jpg";
import sulfato_de_magnesio_heptahidratado from "./../../assets/img/Productos/10_Sulfato_De_Magnesio_Heptahidratado.jpg";
import acido_borico from "./../../assets/img/Productos/11_Ácido_Bórico.jpg";
// import aminoacidos_80 from "./../../assets/img/Productos/12_Aminoácidos_80.jpg";
import sulfato_de_amonio from "./../../assets/img/Productos/13_Sulfato_De_Amonio.jpg";
import octoborato_de_sodio from "./../../assets/img/Productos/14_Octoborato_De_Sodio.jpg";
import acido_nitrico from "./../../assets/img/Productos/15_Ácido_Nitrico.jpg";
import sulfato_de_cobre from "./../../assets/img/Productos/16_Sulfato_De_Cobre.jpg";
import molibdato_de_sodio from "./../../assets/img/Productos/17_Molibdato_De_Sodio.jpg";
import nitrato_de_magnesio from "./../../assets/img/Productos/18_Nitrato_de_Magnesio.jpg";
import sulfato_de_zinc_heptahidratado from "./../../assets/img/Productos/19_Sulfato_De_Zinc_Heptahidratado.jpg";
import sulfato_de_manganeso from "./../../assets/img/Productos/20_Sulfato_De_Manganeso.jpg";
import sulfato_ferroso from "./../../assets/img/Productos/21_Sulfato_Ferroso.jpg";
import { t } from "i18next";

export default function Productos() {
  const products = [
    {
      id: 1,
      name: t("AMMONIUM_NITRATE"),
      image: nitrato_de_amonio,
    },
    {
      id: 2,
      name: t("POTASSIUM_NITRATE"),
      image: nittrato_de_potasio,
    },
    {
      id: 3,
      name: t("MAP_12_61_0"),
      image: map_12_61_0,
    },
    {
      id: 4,
      name: t("NOMBRE_SULFATO_DE_POTASIO"),
      image: sulgato_de_potasio,
    },
    {
      id: 5,
      name: t("ACID_PHOSPHORIC"),
      image: acido_fosforico,
    },
    {
      id: 6,
      name: t("MKP_0_52_34"),
      image: mkp_0_52_34,
    },
    {
      id: 7,
      name: t("NOMBRE_NITRATO_DE_CALCIO"),
      image: nitrato_de_calcio,
    },
    {
      id: 8,
      name: t("POTASSIUM_CHLORIDE"),
      image: muriato_de_potasio,
    },
    {
      id: 9,
      name: t("UREA"),
      image: ureal,
    },
    {
      id: 10,
      name: t("NOMBRE_SULFATO_DE_MAGNESIO_HEPTAHIDRATADO"),
      image: sulfato_de_magnesio_heptahidratado,
    },
    {
      id: 11,
      name: t("ACID_BORIC"),
      image: acido_borico,
    },
    // {
    //   id: 12,
    //   name: "Aminoacidos 80%",
    //   image: aminoacidos_80,
    // },
    {
      id: 12,
      name: t("AMMONIUM_SULFATE"),
      image: sulfato_de_amonio,
    },
    {
      id: 13,
      name: t("SODIUM_OCTOBORATE"),
      image: octoborato_de_sodio,
    },
    {
      id: 14,
      name: t("NOMBRE_ACIDO_NITRICO"),
      image: acido_nitrico,
    },
    {
      id: 15,
      name: t("NOMBRE_SULFATO_DE_COBRE"),
      image: sulfato_de_cobre,
    },
    {
      id: 16,
      name: t("SODIUM_MOLYBDATE_DIHYDRATE"),
      image: molibdato_de_sodio,
    },
    {
      id: 17,
      name: t("NOMBRE_NITRATO_DE_MAGNESIO"),
      image: nitrato_de_magnesio,
    },
    {
      id: 18,
      name: t("NOMBRE_SULFATO_DE_ZINC_HEPTAHIDRATADO"),
      image: sulfato_de_zinc_heptahidratado,
    },
    {
      id: 19,
      name: t("COMPONENTE_SULFATO_DE_MANGANESO"),
      image: sulfato_de_manganeso,
    },
    {
      id: 20,
      name: t("NOMBRE_SULFATO_FERROSO_HEPTAHIDRATADO"),
      image: sulfato_ferroso,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Número de elementos a mostrar en PC
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Cuando el ancho de la pantalla sea menor o igual a 768px (puedes ajustar este valor)
        settings: {
          slidesToShow: 1, // Número de elementos a mostrar en dispositivos móviles
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // Array de colores para los divs del carrusel
  const handleProductClick = (productId) => {
    // Implementa la lógica de lo que deseas que haga el botón aquí
    // alert(`Haz hecho clic en el producto ${productId}`);
  };

  return (
    <div id="Produtos" className="product-carousel-container">
      <h2 style={{ textAlign: 'center' }}>{t("otros_productos")}</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="product-slide">
            <Link
              to={`/producto/${product.name}`}
              reloadDocument
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="product-contenedor">
                <img
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <h4 className="nombre_producto">{product.name}</h4>
                <button
                  className="product-button"
                  onClick={() => handleProductClick(product.id)}
                >
                  <span className="text_boton">Ver Detalles</span>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}
