import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-lazy-load-image-component/src/effects/blur.css";

import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom";
// import Error404 from "./components/Error404/Error404";
// import Home from "./Home/Home";
import ProductoExhibicion from "./components/Producto_exhibición/ProductoExhibicion";
import { Suspense, lazy, useEffect } from "react";
import LanguageSelector from "./components/Floating/LanguageSelector";
import { useTranslation } from "react-i18next";
import { LanguageProvider } from "./Context/LanguageContext";

const Error404 = lazy(() => import("./components/Error404/Error404"));
const Home = lazy(() => import("./Home/Home"));


function App() {



  return (
    <LanguageProvider>
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layyout />}>
              <Route path="*" element={<Error404 />} />
              <Route path="/" element={<Home />}></Route>
              <Route
                path="/producto/:nombre_producto"
                element={<ProductoExhibicion />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
        <LanguageSelector />

      </div>
    </LanguageProvider>


  );
}

function Layyout() {
  return (
    <>
      <Suspense fallback={<h1>Cargando...</h1>}>
        <Outlet />
      </Suspense>
    </>
  );
}

export default App;
