/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from "react";
import "./Prueba2.css";
import logo from "./../../assets/logo/Logo_Novagen_Pantone_trasparente.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
function Prueba2() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    const buscardor = document.getElementById("buscador_id");
    setMenuActive(!menuActive);
    if (menuActive) {
      buscardor.classList.add("mobiles");
    } else {
      buscardor.classList.remove("mobiles");
      console.log(menuActive);
    }
  };

  const productos = [
    //1
    {
      id: 1,
      nombre: "ÁCIDO BÓRICO",
      desc: "El Ácido Bórico desempeña un papel crucial en la horticultura y agricultura, ya que constituye una fuente esencial de Boro, uno de los micronutrientes de vital importancia para el desarrollo óptimo de las plantas y sus frutos. Este compuesto ejerce influencia directa en procesos fundamentales como la polinización, la formación de semillas y la regulación del equilibrio entre almidón y azúcares en los tejidos vegetales.",
      formula: "H₃BO₃",
    },
    //2
    {
      id: 2,
      nombre: "ÁCIDO FOSFÓRICO",
      desc: "El Ácido Fosfórico optimiza la dispersión de los nutrientes, logra un equilibrio mineral en la planta, ayuda a la síntesis de proteínas, contribuye al fortalecimiento de la raíz, acelera la madurez, convierte fosfatos naturales en el suelo a compuestos más solubles, previene obstrucciones en el sistema de riego y ayuda a tener una mayor penetración en el suelo y en la planta.",
      formula: "H₃PO₄",
    },
    //3
    {
      id: 3,
      nombre: "ÁCIDO NÍTRICO",
      desc: "El Ácido Nítrico aplicado al suelo alcalino es de fundamental ayuda debido a que permite asimilar mejor el Calcio, Fósforo, Zinc, Boro y otros elementos importantes para la nutrición del cultivo. Este producto contribuye a un balance importante en el suelo y favorecer a la buena absorción de los nutrientes por parte de la planta.",
      formula: "HNO₃",
    },
    //4
    // {
    //   id: 4,
    //   nombre: "AMINOÁCIDOS 80%",
    //   desc: "Los Aminoácidos en una concentración del 80% se componen predominantemente por polipéptidos cortos que promueven la buena absorción de la planta. Este es un compuesto es empleado mayormente en prácticas agrícolas orgánicas con el propósito de potenciar la absorción de Nitrógeno y asegurar una nutrición óptima del cultivo.",
    //   formula: "-",

    //   composicion: [
    //     {
    //       id: 1,
    //       componente: "Aminoácidos libres",
    //       especificaciones: "80.00%",
    //     },
    //     {
    //       id: 2,
    //       componente: "Estado Físico",
    //       especificaciones: "Polvo Amarillo",
    //     },
    //     {
    //       id: 3,
    //       componente: "Densidad",
    //       especificaciones: "0.60 g/ml",
    //     },
    //     {
    //       id: 4,
    //       componente: "Solubilidad",
    //       especificaciones: "90.00% / 100g de agua",
    //     },
    //   ],
    // },
    //4
    {
      id: 4,
      nombre: "MAP 12-61-0",
      desc: "El Fosfato Monoamónico (MAP) constituye una notable fuente de Fósforo y Nitrógeno, nutrientes esenciales para el desarrollo vegetal. La solución de MAP exhibe un pH levemente ácido, otorgándole propiedades que lo conviereten en un fertilizante especialmente aplicado para suelos con pH alcalino o neutro. La capacidad del producto para suministrar de manera equilibrada fósforo y nitrógeno, junto con su adaptabilidad a condiciones de acidez en el suelo, lo convierte en una opción estratégica para potenciar la disponibilidad de nutrientes esenciales y maximizar la eficiencia nutricional en sistemas de cultivo.",
      formula: "(NH₄)H₂PO₄",
    },
    //5
    {
      id: 5,
      nombre: "MKP 0-52-34",
      desc: "El fosfato monopotásico (MKP) se destaca por ser una amplia fuente de potasio y fósforo, proporcionando dos nutrientes esenciales sin contener nitrógeno en su formulación. Este fertilizante, altamente soluble en agua, desempeña un papel crucial en el desarrollo de raíces y tejidos foliares, contribuyendo significativamente al crecimiento vegetal. Además de su eficaz aporte nutricional, el MKP estimula la etapa reproductiva de las plantas al facilitar la activación de enzimas reguladoras y la síntesis de proteínas y carbohidratos. Este producto promueve la etapa reproductiva de las plantas, la activación de enzimas reguladoras y la síntesis de proteínas y carbohidratos, además de proteger a la planta de la sequía o climas muy fríos, la salinidad y otras enfermedades. Muy recomendable para cultivos de frutas ya que amplifican la creación de azúcares en ellos.",
      formula: "KH₂PO₄",
    },
    //6
    {
      id: 6,
      nombre: "MOLIBDATO DE SODIO DIHIDRATADO",
      desc: "El Molibdato de Sodio se emplea de manera frecuente con el propósito de mitigar la acumulación de Nitrato durante el crecimiento de los cultivos, además, actúa como un mejorador de la absorción de nutrientes esenciales para el desarrollo óptimo de las plantas. Este compuesto desempeña un papel adicional como fijador de Nitrógeno, contribuyendo a la eficiente asimilación y utilización de este elemento crítico en los procesos metabólicos y de crecimiento vegetal.",
      formula: "Na₂MoO₄",
    },
    //7
    {
      id: 7,
      nombre: "MURIATO DE POTASIO",
      desc: "El Muriato de Potasio, o Cloruro de Potasio, se configura como una fuente altamente concentrada de potasio, desempeñando una función esencial en la corrección de deficiencias o carencias de este elemento en la planta. Su composición rica en Potasio lo convierte en un insumo estratégico para el fortalecimiento de diversos procesos fisiológicos y metabólicos de las plantas, influyendo directamente en el desarrollo y la calidad de los cultivos. La aplicación cuidadosa del Muriato de Potasio se orienta hacia la optimización de los niveles de Potasio en el suelo, contribuyendo así a asegurar un crecimiento vegetal saludable y la prevención de posibles limitaciones nutricionales que podrían afectar el rendimiento agrícola.",
      formula: "KCl",
    },
    //8
    {
      id: 8,
      nombre: "NITRATO DE AMONIO",
      desc: "El Nitrato de Amonio se emplea como fertilizante, destacando su  contenido de Nitrógeno. Este compuesto es aprovechado por la planta para su nutrición, por otro lado, el Amonio experimenta procesos de oxidación por parte de microorganismos en el suelo. Estos microorganismos realizan la conversión del Amonio a formas Nítricas disponibles para las plantas, actuando así como una fuente de abono de larga duración. El Nitrato de Amonio se posiciona estratégicamente en prácticas agrícolas para proporcionar un suministro continuo y balanceado de Nitrógeno a lo largo del ciclo de crecimiento de los cultivos, contribuyendo significativamente al rendimiento y la salud vegetal.",
      formula: "NH₄NO₃",
    },
    //9
    {
      id: 9,
      nombre: "NITRATO DE CALCIO",
      desc: "El Nitrato de Calcio es un componente esencial para el desarrollo radicular, desempeñando un papel crítico en la formación y fortalecimiento de la pared celular en las plantas. Su participación activa en la mejora de la integridad de la pared celular incide directamente en la calidad de los frutos, logrando de esta manera un color más vibrante, un sabor mejorado y una mayor duración para el manejo postcosecha. La presencia de Nitrato de Calcio en el suelo contribuye a potenciar la resistencia estructural de las plantas, influyendo positivamente en la calidad organoléptica de los cultivos y, por ende, en su valor comercial.",
      formula: "Ca(NO₃)₂",
    },
    //10
    {
      id: 10,
      nombre: "NITRATO DE MAGNESIO",
      desc: "El Nitrato de Magnesio, denominado de acuerdo a su composición química, se configura como un fertilizante que despliega una función regulatoria en los niveles de Nitrato y Magnesio, como tal. Ambos nutrientes son esenciales para el desarrollo vegetal. Este compuesto desempeña un papel clave en la biosíntesis de clorofila, al contribuir de manera directa a la síntesis de pigmentos fotosintéticos y, por ende, al óptimo aprovechamiento de la luz solar para la producción de carbohidratos.",
      formula: "Mg(NO₃)₂",
    },
    //11
    {
      id: 11,
      nombre: "NITRATO DE POTASIO",
      desc: "El Nitrato de Potasio, desempeña una función trascendental en la reconstrucción de las paredes  celulares no solo repercute en la robustez física de las células vegetales, sino que también puede modular respuestas fisiológicas y bioquímicas que fortalecen la capacidad de defensa de la planta frente a patógenos. ",
      formula: "KNO₃",
    },
    //12
    {
      id: 12,
      nombre: "OCTOBORATO DE SODIO",
      desc: "El Octoborato de Sodio, al ser una fuente altamente concentrada de Boro, desempeña roles cruciales en diversos procesos fisiológicos de las plantas. Su presencia facilita la generación de polen, participa activamente en el engrosamiento de la pared celular, favorece el desarrollo de raices y contribuye a establecer un equilibrio metabolico entre almidón y azúcares en la planta. La importancia del Boro, proveniente del Octoborato de Sodio, radica en su participación como cofactor en numerosas enzimas, influyendo directamente en la regulación de procesos biológicos clave. La aplicación estratégica de octoborato de sodio se considera fundamental para optimizar la productividad agrícola al mejorar la reproducción y el desarrollo estructural de las plantas.",
      formula: "Na₂B₈O₁₃",
    },
    //13
    {
      id: 13,
      nombre: "SULFATO DE AMONIO",
      desc: "El Sulfato de Amonio, un fertilizante de doble propósito, se destaca por proporcionar tanto Azufre como Nitrógeno, nutrientes fundamentales para el desarrollo óptimo de las plantas. Este compuesto es empleado de manera habitual con el propósito de corregir deficiencias nutricionales y mejorar la calidad del suelo, especialmente en situaciones donde se requiere una acidificación efectiva de suelos con pH alcalino. La combinación de Nitrógeno y Azufre en el Sulfato de Amonio no solo nutre directamente a las plantas, sino que también contribuye a modificar las características químicas del suelo, promoviendo condiciones más favorables para la absorción de nutrientes por parte de los cultivos.",
      formula: "(NH₄)₂SO₄",
    },
    //14
    {
      id: 14,
      nombre: "SULFATO DE COBRE",
      desc: "El Sulfato de Cobre, un fertilizante caracterizado por su alta concentración de dicho metal, estimula procesos metabólicos fundamentales en las plantas. Su función principal reside en favorecer la fotosíntesis, la absorción de nutrientes y la respiración celular. Este compuesto se aplica estratégicamente en suelos con deficiencia de cobre, ya que este metal actúa como un cofactor esencial en diversas enzimas involucradas en procesos clave del metabolismo vegetal.",
      formula: "CuSO₄",
    },
    //15
    {
      id: 15,
      nombre: "SULFATO DE MAGNESIO HEPTAHIDRATADO",
      desc: "El Sulfato de Magnesio, en su capacidad correctiva, desempeña un papel crucial para la coloración verde de las plantas, un indicador de la presencia de clorosis causada por deficiencia de Magnesio. Este compuesto no solo actúa como una fuente directa de Magnesio, sino que también se extiende al estímulo de la fotosíntesis, a través de su contribución como cofactor en la molécula de clorofila, optimizando así la eficiencia de conversión de la luz solar en energía química. El compuesto además, promueve un mayor crecimiento de las raíces, lo cual incrementa la absorción de nutrientes,  la estabilidad estructural de la planta y el aumento del nivel de grados Brix, resultando en frutos más dulces y mejorando la calidad organoléptica de los productos cosechados.",
      formula: "MgSO₄·7H₂O",
    },
    //16
    {
      id: 16,
      nombre: "SULFATO DE MANGANESO MONOHIDRATADO",
      desc: "El Sulfato de Manganeso, al ser un fertilizante específico, se emplea para abordar la carencia de Manganeso en las plantas, una condición que puede afectar adversamente su desarrollo y rendimiento. Este compuesto realiza contribuciones significativas en la estabilización de la fotosíntesis, la asimilación de Nitrógeno, mejora en la respiración celular y es un cofactor de enzimas clave en la síntesis y metabolismo de compuestos nitrogenados. Esta contribución es esencial para optimizar la absorción y utilización eficiente del Nitrógeno por parte de las plantas.",
      formula: "MnSO₄",
    },
    //17
    {
      id: 17,
      nombre: "SULFATO DE POTASIO",
      desc: "El Sulfato de Potasio, al ser una fuente esencial de Potasio, su contribución clave reside en la síntesis de proteínas, donde el Potasio actúa como cofactor esencial para numerosas enzimas involucradas en la formación de enlaces peptídicos y la estructuración de macromoléculas proteicas. Estos procesos promueven la resistencia a enfermedades en los tejidos vegetales al activar mecanísmos de defensa inmunológica, regula el balance iónico e hídrico y contribuye a la preservación del pigmento y aroma de las plantas.",
      formula: "K₂SO₄",
    },
    //18
    {
      id: 18,
      nombre: "SULFATO DE ZINC HEPTAHIDRATADO",
      desc: "El Sulfato de Zinc Heptahidratado, como fertilizante altamente hidrosoluble, se destaca por su capacidad para regular los niveles de Zinc en los cultivos, desempeñando un papel crucial en la optimización de procesos fisiológicos. La presencia adecuada de Zinc es fundamental para diversos aspectos del metabolismo vegetal, y este compuesto se convierte en una fuente eficaz para abordar deficiencias específicas y para el fortalecimiento del sistema inmune de la planta.",
      formula: "ZnSO₄·7H₂O",
    },
    //19
    {
      id: 19,
      nombre: "SULFATO FERROSO HEPTAHIDRATADO",
      desc: "El Sulfato Ferroso, siendo un compuesto específicamente diseñado para abordar deficiencias de Hierro en el suelo, desempeña un papel crucial en la corrección de clorosis férrica, manifestada por la decoloración amarillenta de las hojas. Su aplicación estratégica contribuye al recoloramiento verde de las hojas, ya que el Hierro es un componente esencial de la clorofila actuando como una medida correctiva.",
      formula: "FeSO₄",
    },
    //20
    {
      id: 20,
      nombre: "UREA",
      desc: "La Urea se incluye en los fertilizantes para poder incrementar los niveles de nitrógeno en la planta. El nitrógeno es fundamental para el metabolismo de la planta, por lo que la Urea es una gran fuente de este elemento. La Urea, integrada en formulaciones de fertilizantes, se emplea con el propósito de elevar los niveles de Nitrógeno en la planta. El Nitrógeno, siendo un elemento esencial, desempeña funciones críticas en el metabolismo vegetal. La Urea, es sometida a procesos de hidrólisis en el suelo, donde enzimas específicas la descomponen en Amonio y Dióxido de Carbono. Este Amonio resultante es posteriormente transformado en Nitratos por microorganismos del suelo, haciendo que el Nitrógeno sea accesible para la planta.",
      formula: "CO(NH₂)₂",
    },
    //21
    {
      id: 21,
      nombre: "PROALGEN",
      desc: "PROALGEN es un compuesto especializado con diversas características: mejora la estructura del suelo para romper la compactación, es respetuoso con el medio ambiente y regula la microecología del suelo, disminuyendo residuos de pesticidas y metales pesados, controla el crecimiento de patógenos del suelo, reduce plagas subterráneas, es rico en oligoelementos medianos, promueve la diferenciación de los botones florales, aumenta la tasa de cuajado de la fruta, promueve la expansión y peso de la fruta. El producto también complementa el sistema de raíces y lo desarrolla, previene el crecimiento excesivo, induce a la planta a producir fitoalexinas, mejora el color de la fruta, contribuye a la dureza y mejora del contenido de materia sólida y la estabilidad de almacenamiento.",
      formula: "CO(NH₂)₂",
    },
    //22
    {
      id: 22,
      nombre: "HUMINOX-A",
      desc: "HUMINOX A es un compuesto especializado con diversas características: Facilidad de absorción por los cultivos, regula y mejora efectivamente la calidad del suelo, mejora la utilización de otros fertilizantes, promueve el crecimiento del cultivo, mejora la calidad de las siembras, aumenta los rendimientos de los cultivos alimentarios en más del 10% con una maduréz temprana de ente 5 y 7 días. Otra de sus ventajas es que aumenta los rendimientos de los cultivos comerciales en más del 20% con una maduréz temprana de entre 6 y 8 días. Además, mejora la resistencia inmunológica de los cultivos, previniendo de esta manera, posibles enfermedades y al anidamiento. Otras características importantes de este producto son: la mejora activa de la estructura de los agregados del suelo, aumenta la cantidad de microorganismos beneficiosos y el contenido de materia orgánica, reduciendo la densidad aparente del suelo, conduce a la retención de agua, aireación, y proporciona mejores condiciones para el crecimiento del cultivo.",
      formula: "-",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredProducts = productos.filter((producto) =>
      producto.nombre.toLowerCase().includes(term)
    );
    setSearchResults(filteredProducts);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center fixed-top"
      style={{
        width: "100%",
        height: "10vh",
        border: "1px solid #000",
        backgroundColor: "#1D1D1B",
      }}
    >


      <Link to={"/"} className="link_img">
        <img src={logo} style={{ height: "100%" }} alt="logo" />
      </Link>
      {/* <img src={logo} className="logo_img" alt="logo" /> */}
      <div className={`menu${menuActive ? " active" : ""}`}>
        <div className="toggle" onClick={toggleMenu}>
          <span style={{ color: "white" }}>
            {menuActive ? (
              <AiOutlineClose size={26} color="white" />
            ) : (
              <AiOutlineMenu size={26} color="white" />
            )}
          </span>
        </div>
        <li style={{ "--i": 0 }}>
          <a
            className="ciruclo-1 fuente"
            href="/#inicio"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            INICIO
          </a>
        </li>
        <li style={{ "--i": 1 }}>
          <a
            className="ciruclo-2 fuente"
            href="/#quienes_somos"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            ¿QUIÉNES SOMOS?
          </a>
        </li>
        <li style={{ "--i": 2 }}>
          <a
            className="ciruclo-3 fuente"
            href="/#historia_seccion"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            HISTORIA
          </a>
        </li>
        <li style={{ "--i": 3 }}>
          <a
            className="ciruclo-4 fuente"
            href="/#Produtos"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            PRODUCTOS
          </a>
        </li>
        <li style={{ "--i": 4 }}>
          <a
            className="ciruclo-5 fuente"
            href="/#Footer"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            CONTACTO
          </a>
        </li>
      </div>

      {/* <form
        class="d-flex"
        role="search"
        style={{ position: "absolute", right: 0 }}
      >
        <button class="btn btn-outline-success" type="submit">
          Search
        </button>
        <input
          class="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
      </form> */}

      {/* Dropdown de búsqueda */}
      <div id="buscador_id" className="dropdown ml-auto buscador mobiles">
        <input
          id="searchInput"
          className="form-control"
          type="search"
          placeholder="Buscar por nombre"
          aria-label="Buscar"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="dropdown-menu show" aria-labelledby="searchInput">
          {searchResults.map((product, index) => (
            <a
              href={`/producto/${product.nombre}`}
              key={index}
              className="dropdown-item"
            >
              <strong>{product.nombre}</strong>
              <br />
              {product.descripcion}
            </a>
          ))}
        </div>
      </div>

      {menuActive ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            top: 0,
            zIndex: -5,
          }}
        ></div>
      ) : null}
    </div>
  );
}

export default Prueba2;
